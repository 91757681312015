<template>
  <div class="events" v-if="!event">
      Loading.....
  </div>
  <div class="events" v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.description }}</p>
    <span>@ {{ event.time }} on {{ event.date }}</span>    
  </div>
</template>

<script>
// @ is an alias to /src
import EventService from "@/services/EventService";

export default {
  name: "EventDetails",
  props: ['id'],
  data() {
    return {
      event: null,
    }
  },
  created() {
    EventService.getEvent(this.id)
    .then(response => this.event = response.data)
    .catch(error => console.log(error))
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>